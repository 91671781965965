import React from 'react';

const Airplane = (theme) => (
  <svg
    id="b8eef085-bbb0-4c81-9a53-f8aa424b48dc"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 1063.13 672.74"
  >
    <path
      d="M928.08,510.29c-9.77,3.6-26.93,6.56-46.79,8.94-54,6.48-128,8.78-128,8.78S372.88,559.56,87.77,442.22c0,0-41.47-21.74-3-51a125.29,125.29,0,0,1,18.82-11.48l.47-.47a99.89,99.89,0,0,1,14.13-10.89c14.77-9.55,39.71-20.87,70.07-15.74L937.08,473.75S964.43,496.86,928.08,510.29Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.airplaneColor}
    />
    <path
      d="M778.88,455.4,935.64,344.18l69.09,11.95L937.08,473.75S780.68,475,778.88,455.4Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.airplaneColor}
    />
    <polygon
      points="315.78 274.2 628.03 186.63 678.36 197.15 537.31 260.6 493.23 309.92 315.78 274.2"
      fill={theme.airplaneColor}
    />
    <path
      d="M881.29,519.23c-54,6.48-128,8.78-128,8.78S372.88,559.56,87.77,442.22c0,0-41.47-21.74-3-51l.13.54s61.52,41.58,355.46,76.29Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.airplaneBottomColor}
    />
    <path
      d="M831.1,516.83l139.6,57,35.07-3.89-83.5-62.76S821.27,485.63,831.1,516.83Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.airplaneColor}
    />
    <path
      d="M135.52,397.85c-6.66.11-21.92-11.06-31.41-18.58a99.89,99.89,0,0,1,14.13-10.89c7.65,5.87,21.26,15.37,28.83,15C158,382.93,145.55,397.67,135.52,397.85Z"
      transform="translate(-68.44 -113.63)"
      fill="#0d1418"
    />
    <path
      d="M769.73,713.86l-89.63,4.69-252-169.92-46.78-31.55L358.6,501.75c42.63-36,216.56,13.65,216.56,13.65,3.33,2.81,4.39,7.48,4.24,12.64-.29,9.66-4.83,21-6.58,25.08-.42,1-.68,1.53-.68,1.53Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.airplaneColor}
    />
  </svg>
);

export const Clouds = (theme) => (
  <svg
    id="b8eef085-bbb0-4c81-9a53-f8aa424b48dc"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 1063.13 672.74"
  >
    <path
      d="M445.3,665.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S406,633,411.62,642a18.5,18.5,0,0,0,15.95,8.77h.25C435.46,650.75,442.08,656.87,445.3,665.81Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M913.55,612.76,898,622.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14,0,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,913.55,612.76Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M884.3,652.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S845,620,850.62,629a18.5,18.5,0,0,0,16,8.77h.25C874.46,637.75,881.08,643.87,884.3,652.81Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M913.55,284.76,898,294.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14.05,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,913.55,284.76Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M884.3,324.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S845,292,850.62,301a18.5,18.5,0,0,0,16,8.77h.25C874.46,309.75,881.08,315.87,884.3,324.81Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M271.53,249.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,271.53,249.37Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M256.94,269.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5.05-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,8,4.37h.13C252,261.83,255.33,264.89,256.94,269.35Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M961.53,738.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,961.53,738.37Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M946.94,758.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5.05-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,8,4.37h.13C942,750.83,945.33,753.89,946.94,758.35Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M241.53,764.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,241.53,764.37Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M226.94,784.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5.05-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,8,4.37h.13C222,776.83,225.33,779.89,226.94,784.35Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M599.55,147.76,584,157.6l9.42-17.13a15.35,15.35,0,0,0-9.38-3.35h-.25a17.21,17.21,0,0,1-3.22-.24l-5.26,3.34,2.25-4.1a18.78,18.78,0,0,1-9.2-7l-9.41,6,5.94-10.82c-5.5-6.61-12.92-10.66-21.09-10.66-9.79,0-18.51,5.82-24.14,14.9a18.06,18.06,0,0,1-16,8.6h-.52c-10.81,0-19.57,12.25-19.57,27.37s8.76,27.37,19.57,27.37a14.6,14.6,0,0,0,6.81-1.71,28.28,28.28,0,0,1,23.69-.46,24.71,24.71,0,0,0,20.14.05,28.27,28.27,0,0,1,23.47.45,14.72,14.72,0,0,0,6.75,1.67c10.81,0,19.57-12.25,19.57-27.37A34.77,34.77,0,0,0,599.55,147.76Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M570.3,187.81a29,29,0,0,0-16.77,1.82,24.63,24.63,0,0,1-20.13,0,28.21,28.21,0,0,0-23.69.46,14.73,14.73,0,0,1-6.82,1.71c-9.6,0-17.59-9.68-19.25-22.44a18.26,18.26,0,0,0,4.8-5.17c5.63-9.07,14.35-14.89,24.14-14.89S531,155,536.62,164a18.5,18.5,0,0,0,16,8.77h.25C560.46,172.75,567.08,178.87,570.3,187.81Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
    <path
      d="M1129.53,319.37l-7.75,4.91,4.7-8.54a7.62,7.62,0,0,0-4.68-1.68h-.12a9.54,9.54,0,0,1-1.61-.12l-2.62,1.66,1.12-2a9.36,9.36,0,0,1-4.59-3.49l-4.69,3,3-5.39a13.71,13.71,0,0,0-10.52-5.32c-4.88,0-9.23,2.9-12,7.43a9,9,0,0,1-8,4.29h-.26c-5.39,0-9.76,6.12-9.76,13.66s4.37,13.65,9.76,13.65a7.32,7.32,0,0,0,3.4-.85,14.07,14.07,0,0,1,11.82-.23,12.31,12.31,0,0,0,10,0,14.08,14.08,0,0,1,11.71.23,7.42,7.42,0,0,0,3.36.83c5.39,0,9.76-6.11,9.76-13.65A17.42,17.42,0,0,0,1129.53,319.37Z"
      transform="translate(-68.44 -113.63)"
      fill={theme.cloudColor}
    />
    <path
      d="M1114.94,339.35a14.36,14.36,0,0,0-8.37.91,12.44,12.44,0,0,1-5,1.06,12.26,12.26,0,0,1-5-1.09,14.1,14.1,0,0,0-11.82.24,7.28,7.28,0,0,1-3.39.85c-4.79,0-8.78-4.83-9.61-11.19a9.4,9.4,0,0,0,2.4-2.58c2.8-4.53,7.15-7.43,12-7.43s9.18,2.87,12,7.34a9.22,9.22,0,0,0,7.95,4.37h.13C1110,331.83,1113.33,334.89,1114.94,339.35Z"
      transform="translate(-68.44 -113.63)"
      opacity="0.03"
    />
  </svg>
);

export const CodeIcon = (theme) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    stroke={theme.airplaneColor}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-code"
  >
    <polyline points="16 18 22 12 16 6" />
    <polyline points="8 6 2 12 8 18" />
  </svg>
);

export const LayoutIcon = (theme) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      stroke={theme.color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-layout"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <line x1="3" y1="9" x2="21" y2="9" />
      <line x1="9" y1="21" x2="9" y2="9" />
    </svg>
  );
};

export const LandingPageSVG = (theme) => (
  <svg
    width="384px"
    height="237px"
    viewBox="0 0 384 237"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
  >
    <path
      id="desk"
      d="M382 186.5 L1 186.5 C0.448 186.5 0 186.052 0 185.5 0 184.948 0.448 184.5 1 184.5 L382 184.5 C382.552 184.5 383 184.948 383 185.5 383 186.052 382.552 186.5 382 186.5 Z"
      fill={theme.desk}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="hair"
      d="M226.059 28.5 C226.059 43.412 213.971 55.5 199.059 55.5 184.147 55.5 172.059 43.412 172.059 28.5 172.059 13.588 184.147 1.5 199.059 1.5 213.971 1.5 226.059 13.588 226.059 28.5 Z"
      fill={theme.hair}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="skin"
      d="M173.405 201.826 L172.69 189.588 219.556 181.003 220.612 199.067 Z"
      fill={theme.skin}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="shoe"
      d="M175.675 180.641 L177.055 204.245 177.055 204.245 162.194 205.113 162.194 205.113 159.945 166.648 159.945 166.648 C168.153 166.168 175.196 172.433 175.675 180.641 Z"
      fill={theme.shoes}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="skin-2"
      d="M193.789 201.714 L186.004 211.185 145.768 185.666 157.257 171.688 Z"
      fill={theme.skin}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="shoe-2"
      d="M177.737 215.726 L192.751 197.46 192.751 197.46 204.251 206.913 204.251 206.913 179.785 236.679 179.785 236.679 C173.434 231.459 172.517 222.078 177.737 215.726 Z"
      fill={theme.shoes}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="desk-2"
      d="M171.387 157.67 L166.067 182.749 C165.819 183.918 166.107 185.137 166.852 186.072 167.596 187.006 168.72 187.559 169.915 187.578 L222.213 188.422 C223.407 188.441 224.547 187.926 225.321 187.018 226.096 186.11 226.424 184.903 226.217 183.727 L221.642 157.805 C221.305 155.893 219.644 154.5 217.703 154.5 L175.3 154.5 C173.41 154.5 171.779 155.822 171.387 157.67 Z"
      fill={theme.desk}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="pants"
      d="M180.059 166.5 C180.059 166.5 129.059 134.5 119.059 151.5 114.059 160 116.559 168.25 120.309 174.375 123.789 179.952 128.785 184.423 134.712 187.266 L176.059 207.5 191.059 191.5 181.059 182.5 Z"
      fill={theme.pants}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="pants-2"
      d="M206.059 161.5 C206.059 161.5 272.059 142.5 259.059 178.5 246.059 214.5 197.059 200.5 197.059 200.5 L192.059 185.5 209.059 173.5 Z"
      fill={theme.pants}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="face-skin"
      d="M223.959 34.665 C223.959 48.23 212.962 59.226 199.398 59.226 185.833 59.226 174.837 48.23 174.837 34.665 174.837 21.101 185.833 10.104 199.398 10.104 212.962 10.104 223.959 21.101 223.959 34.665 Z"
      fill={theme.skin}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="sweater"
      d="M200.685 165.469 C190.479 165.314 180.325 163.977 170.427 161.482 L170.135 161.402 156.429 98.357 C153.878 86.892 160.288 75.327 171.362 71.414 187.892 65.483 205.908 65.124 222.662 70.39 L222.662 70.39 C234.32 74.147 241.129 86.237 238.297 98.154 L222.989 161.35 222.773 161.452 C216.411 164.466 208.456 165.469 200.685 165.469 Z"
      fill={theme.sweater}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="hand-skin"
      d="M182.274 170.366 C183.282 167.566 185.478 165.356 188.272 164.331 191.066 163.305 194.17 163.57 196.75 165.054 L223.736 141.63 226.178 160.038 200.177 179.46 C197.335 183.722 191.754 185.185 187.186 182.865 182.619 180.545 180.509 175.175 182.274 170.366 Z"
      fill={theme.skin}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="sweater-2"
      d="M208.012 175.476 C207.573 175.475 207.136 175.411 206.716 175.285 205.33 174.882 204.226 173.832 203.753 172.469 L200.496 163.24 C199.872 161.494 200.39 159.544 201.798 158.338 L238.567 136.264 224.073 71.603 224.442 71.514 C224.592 71.477 239.442 68.097 245.527 84.324 249.789 95.689 265.559 120.401 264.446 137.101 264.011 143.977 258.914 149.656 252.124 150.828 L210.736 174.541 C209.957 175.145 208.999 175.474 208.012 175.476 Z"
      fill={theme.sweater}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="hand-skin-2"
      d="M212.136 168.173 C211.128 165.373 208.932 163.163 206.139 162.137 203.345 161.112 200.24 161.377 197.661 162.861 L170.674 139.436 168.233 157.844 194.234 177.267 C197.076 181.529 202.657 182.992 207.224 180.671 211.792 178.351 213.902 172.982 212.136 168.173 Z"
      fill={theme.skin}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="sweater-3"
      d="M189.106 175.476 C188.12 175.474 187.162 175.145 186.382 174.541 L141.994 152.828 C135.204 151.656 130.107 145.977 129.672 139.101 128.559 122.401 147.329 95.689 151.591 84.324 157.676 68.097 172.526 71.476 172.677 71.514 L173.045 71.603 155.551 138.264 195.32 158.338 C196.728 159.544 197.246 161.494 196.622 163.24 L193.365 172.469 C192.892 173.832 191.788 174.882 190.402 175.285 189.982 175.411 189.545 175.475 189.106 175.476 Z"
      fill={theme.sweater}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="mac"
      className="macbook"
      d="M242.059 185.5 L153.059 185.5 C149.195 185.495 146.064 182.364 146.059 178.5 L146.059 129.5 C146.064 125.636 149.195 122.505 153.059 122.5 L242.059 122.5 C245.923 122.504 249.055 125.636 249.059 129.5 L249.059 178.5 C249.055 182.364 245.923 185.495 242.059 185.5 Z"
      fill={theme.primary}
      fillOpacity="1"
      stroke="none"
      cursor="pointer"
      onClick={theme.toggler}
    />
    <path
      id="mac-logo"
      d="M203.559 154 C203.559 157.314 200.873 160 197.559 160 194.245 160 191.559 157.314 191.559 154 191.559 150.686 194.245 148 197.559 148 200.873 148 203.559 150.686 203.559 154 Z"
      fill={theme.logo}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="hair-2"
      d="M174.591 33.666 C173.135 29.973 175.523 25.573 179.066 23.785 182.609 21.996 186.885 22.336 190.675 23.515 193.859 24.506 196.844 26.041 199.951 27.253 203.015 28.611 206.361 29.207 209.704 28.99 213.019 28.616 216.317 26.758 217.673 23.71 219.079 20.552 218.123 16.697 215.958 14.002 213.655 11.396 210.674 9.48 207.346 8.467 200.039 5.892 191.598 5.817 184.814 9.559 178.03 13.301 173.42 21.27 174.822 28.889"
      fill={theme.hair}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower"
      d="M32.662 174.818 C33.898 161.513 37.31 148.501 42.763 136.301 Q45.043 131.209 47.781 126.341 C47.971 125.988 47.848 125.548 47.502 125.345 47.156 125.143 46.712 125.251 46.498 125.59 39.858 137.411 35.207 150.243 32.73 163.573 Q31.696 169.156 31.176 174.818 C31.088 175.771 32.575 175.765 32.663 174.818 Z"
      fill={theme.flower}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower-bloom"
      d="M60.796 119.31 C60.796 124.509 56.581 128.724 51.381 128.724 46.182 128.724 41.967 124.509 41.967 119.31 41.967 114.111 46.182 109.896 51.381 109.896 56.581 109.896 60.796 114.111 60.796 119.31 Z"
      fill={theme.primary}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower-2"
      d="M33.625 175.069 C34.427 166.437 36.641 157.995 40.178 150.081 Q41.657 146.777 43.434 143.619 C43.557 143.39 43.477 143.104 43.253 142.973 43.028 142.841 42.74 142.912 42.601 143.132 38.294 150.801 35.276 159.125 33.669 167.773 Q32.999 171.395 32.661 175.069 C32.604 175.686 33.569 175.682 33.625 175.069 Z"
      fill={theme.flower}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower-bloom-2"
      d="M51.877 139.058 C51.877 142.431 49.142 145.165 45.769 145.165 42.396 145.165 39.662 142.431 39.662 139.058 39.662 135.685 42.396 132.95 45.769 132.95 49.142 132.95 51.877 135.685 51.877 139.058 Z"
      fill={theme.primary}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower-3"
      d="M32.408 174.492 C27.731 167.193 24.285 159.175 22.206 150.759 Q21.34 147.245 20.798 143.662 C20.755 143.405 20.516 143.229 20.258 143.264 20 143.298 19.816 143.531 19.842 143.79 21.163 152.486 23.904 160.906 27.955 168.713 Q29.655 171.981 31.648 175.085 C31.983 175.607 32.741 175.011 32.408 174.492 Z"
      fill={theme.flower}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower-bloom-3"
      d="M25.942 138.629 C25.942 142.002 23.208 144.736 19.835 144.736 16.462 144.736 13.727 142.002 13.727 138.629 13.727 135.256 16.462 132.521 19.835 132.521 23.208 132.521 25.942 135.256 25.942 138.629 Z"
      fill={theme.primary}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="flower-vase"
      d="M13.659 179.896 L13.659 167.896 C13.661 165.412 15.674 163.399 18.158 163.396 L46.159 163.396 C48.643 163.398 50.656 165.412 50.659 167.896 L50.659 179.896 C50.656 182.38 48.643 184.393 46.159 184.396 L18.159 184.396 C15.674 184.393 13.661 182.38 13.659 179.896 Z"
      fill={theme.flower}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="apple"
      d="M314.409 193.901 C314.409 200.478 309.077 203.467 302.5 203.467 295.923 203.467 290.591 200.478 290.591 193.901 290.591 187.324 293.238 179.649 302.5 182.296 311.762 178.326 314.409 187.324 314.409 193.901 Z"
      fill={theme.apple}
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="apple-stem"
      d="M301.92 185.377 C300.962 185.379 300.152 184.668 300.029 183.718 299.635 180.436 297.461 174.01 296.545 171.399 L296.545 171.398 C296.357 170.872 296.439 170.286 296.765 169.832 297.076 169.427 297.545 169.175 298.054 169.138 298.563 169.101 299.063 169.284 299.428 169.64 299.755 169.982 299.924 170.444 299.897 170.916 299.717 174.267 302.16 179.681 303.632 182.61 303.914 183.171 303.902 183.834 303.601 184.385 303.299 184.935 302.746 185.302 302.122 185.366 302.055 185.374 301.987 185.377 301.92 185.377 Z"
      fill="#3f3d56"
      fillOpacity="1"
      stroke="none"
    />
    <path
      id="apple-stem-2"
      d="M310.732 177.985 C314.866 174.602 311.623 167.678 306.449 169.007 305.752 169.173 305.111 169.516 304.585 170.001 301.411 172.999 300.919 180.67 300.919 180.67 300.919 180.67 306.524 181.43 310.732 177.985 Z"
      fill="#3f3d56"
      fillOpacity="1"
      stroke="none"
    />
  </svg>
);

export default Airplane;
